import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
 
<path d="M790 2160 c0 -5 20 -10 44 -10 29 0 52 -7 70 -21 l26 -20 0 -458 0
-458 -23 -21 c-14 -13 -40 -23 -65 -25 -24 -3 -45 -7 -49 -11 -3 -3 174 -6
395 -6 l402 0 0 79 c0 90 19 123 22 39 3 -98 20 195 21 385 l2 187 37 0 c68 0
63 36 60 -482 -2 -360 -5 -474 -15 -480 -16 -11 -69 -10 -86 1 -11 7 -12 13
-4 23 16 20 16 226 0 271 l-14 39 -6 -39 c-4 -21 -7 -88 -7 -149 0 -137 -12
-160 -91 -170 -27 -4 -49 -9 -49 -13 0 -13 543 -5 595 9 236 62 356 254 342
549 -8 170 -47 271 -137 355 -112 105 -204 126 -550 126 -160 0 -250 -4 -250
-10 0 -5 16 -10 35 -10 42 0 90 -22 99 -45 12 -32 6 -326 -9 -426 -19 -124
-39 -167 -96 -199 -41 -22 -56 -24 -216 -28 l-173 -5 0 471 c0 324 3 479 11
495 14 30 48 47 94 47 19 0 35 5 35 10 0 6 -82 10 -225 10 -143 0 -225 -4
-225 -10z m280 -497 c0 -254 -3 -469 -6 -476 -5 -15 -66 -29 -87 -21 -9 3 -12
122 -12 485 l0 480 53 -3 53 -3 -1 -462z m909 166 c156 -33 226 -160 238 -434
10 -213 -27 -388 -98 -469 -56 -63 -121 -88 -246 -94 l-103 -5 0 507 0 506 80
0 c43 0 101 -5 129 -11z m270 -137 c47 -46 62 -71 82 -127 13 -39 25 -79 26
-90 7 -54 7 -206 1 -250 -9 -65 -42 -157 -53 -147 -4 4 -5 1 0 -7 4 -7 4 -16
-1 -20 -5 -3 -22 -25 -37 -49 -15 -23 -39 -48 -52 -54 -14 -6 -25 -15 -25 -20
0 -4 -4 -8 -9 -8 -10 0 6 61 19 70 10 8 39 129 45 190 18 172 3 393 -32 480
-4 8 -7 17 -8 20 0 3 -9 20 -18 38 -27 49 -3 39 62 -26z"/>
<path d="M1158 740 c2 -45 8 -70 15 -70 8 0 11 21 9 70 -2 45 -8 70 -15 70 -8
0 -11 -21 -9 -70z"/>
<path d="M1315 740 c0 -51 4 -70 13 -70 10 0 12 17 10 67 -4 90 -23 93 -23 3z"/>
<path d="M1425 740 c0 -56 3 -70 15 -70 12 0 14 12 10 67 -7 90 -25 92 -25 3z"/>
<path d="M2145 740 c0 -51 4 -70 13 -70 10 0 12 17 10 67 -4 90 -23 93 -23 3z"/>
<path d="M775 735 c0 -51 3 -65 15 -65 12 0 15 14 15 65 0 51 -3 65 -15 65
-12 0 -15 -14 -15 -65z"/>
<path d="M1630 735 c0 -45 4 -65 13 -65 9 0 11 18 9 65 -5 84 -22 84 -22 0z"/>
<path d="M1789 776 c-2 -2 -2 -27 -1 -55 3 -38 8 -51 19 -51 13 0 13 3 3 15
-8 9 -11 30 -7 55 4 35 -1 49 -14 36z"/>
<path d="M934 754 c3 -9 6 -31 6 -50 0 -19 5 -34 10 -34 11 0 14 59 4 84 -3 9
-11 16 -16 16 -6 0 -7 -7 -4 -16z"/>
<path d="M990 723 c4 -38 9 -49 25 -51 16 -3 18 -1 8 9 -7 7 -13 30 -13 51 0
24 -5 38 -13 38 -10 0 -11 -12 -7 -47z"/>
<path d="M1048 720 c2 -29 9 -50 15 -50 8 0 11 17 9 50 -2 29 -9 50 -15 50 -8
0 -11 -17 -9 -50z"/>
<path d="M1104 756 c-3 -8 -4 -29 -2 -47 2 -25 8 -34 23 -36 11 -2 14 0 8 3
-7 3 -13 23 -13 44 0 21 6 41 13 43 7 3 5 6 -6 6 -10 1 -20 -5 -23 -13z"/>
<path d="M1258 720 c3 -30 9 -50 16 -50 8 0 10 14 6 47 -7 64 -26 67 -22 3z"/>
<path d="M1363 759 c10 -6 17 -22 17 -39 0 -17 -7 -33 -17 -39 -15 -9 -15 -10
-2 -11 22 0 39 21 39 50 0 29 -17 50 -39 50 -13 -1 -13 -2 2 -11z"/>
<path d="M1478 745 c-16 -35 3 -75 36 -75 14 0 28 5 31 10 4 7 -2 9 -19 4 -21
-5 -26 -2 -31 19 -4 14 -2 35 5 46 7 14 7 21 0 21 -6 0 -16 -11 -22 -25z"/>
<path d="M1574 745 c-10 -41 3 -75 29 -75 20 1 20 2 5 11 -21 12 -24 59 -6 77
9 9 8 12 -5 12 -9 0 -19 -11 -23 -25z"/>
<path d="M1728 720 c3 -30 9 -50 16 -50 8 0 10 14 6 47 -7 64 -26 67 -22 3z"/>
<path d="M1894 754 c3 -9 6 -31 6 -50 0 -19 5 -34 10 -34 11 0 14 59 4 84 -3
9 -11 16 -16 16 -6 0 -7 -7 -4 -16z"/>
<path d="M1947 763 c-14 -14 -6 -34 19 -45 17 -8 24 -18 21 -30 -6 -23 11 -24
20 -1 4 11 -3 24 -22 38 -15 13 -24 28 -21 34 8 12 -6 15 -17 4z"/>
<path d="M2027 743 c9 -62 14 -73 30 -73 13 0 14 3 5 12 -7 7 -12 29 -12 50 0
25 -5 38 -14 38 -9 0 -12 -9 -9 -27z"/>
<path d="M2088 720 c2 -29 9 -50 15 -50 8 0 11 17 9 50 -2 29 -9 50 -15 50 -8
0 -11 -17 -9 -50z"/>
<path d="M2198 758 c18 -18 15 -65 -5 -77 -15 -9 -15 -10 -2 -11 21 0 39 20
39 46 0 34 -10 54 -28 54 -12 0 -13 -3 -4 -12z"/>
<path d="M1210 695 c0 -14 6 -25 14 -25 9 0 12 8 8 25 -2 14 -8 25 -13 25 -5
0 -9 -11 -9 -25z"/>
<path d="M1844 705 c-8 -19 3 -35 24 -35 14 0 14 2 3 9 -8 5 -11 16 -8 25 4 9
2 16 -4 16 -5 0 -12 -7 -15 -15z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
